<template>
  <el-card shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="space-between">
        <h4>Carcaças cadastradas</h4>
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="medium"
          @click="openCarcassModal(null)"
        ></el-button>
      </el-row>
    </template>
    <el-table
      stripe
      :data="carcasses"
      :cell-style="() => 'text-align:center;'"
      style="width: 100%; z-index: 0"
    >
      <el-table-column
        prop="bought_at"
        label="data"
        :formatter="(r) => formatDate(r.bought_at)"
      >
      </el-table-column>
      <el-table-column prop="code" label="Nº">
        <template #default="c">
          <base-input
            v-on:keyup.enter="() => updateCarcass(c.row)"
            mask="#*"
            v-model="c.row.code"
          ></base-input>
        </template>
      </el-table-column>
      <el-table-column label="peso bruto" :min-width="120">
        <template #default="c">
          <base-input
            type="money"
            :minimumFractionDigits="3"
            v-model="c.row.total_weight"
            v-on:keyup.enter="() => updateCarcass(c.row)"
          ></base-input>
        </template>
      </el-table-column>
      <el-table-column prop="type_formatted" label="tipo"></el-table-column>
      <el-table-column prop="gender_formatted" label="sexo"></el-table-column>
      <el-table-column prop="standard" label="qualificação"></el-table-column>
      <el-table-column prop="provider.name" label="produtor"></el-table-column>
      <el-table-column label="ações" :min-width="80">
        <template #default="emp">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openCarcassModal(emp.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteCarcass(emp.row)"
                title="
              Remover carcaça?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <carcass-modal
      :showModal="showCarcassModal"
      :carcass="carcass"
      @close-modal="showCarcassModal = false"
      @should-update="fetchCarcasses"
    ></carcass-modal>
  </el-card>
</template>

<script>
import CarcassModal from "./modals/CarcassModal.vue";
import BaseInput from "../components/BaseInput.vue";
import { ElNotification } from "element-plus";

export default {
  name: "carcassesPage",
  components: { CarcassModal, BaseInput },
  data: () => ({
    hasError: false,
    isLoading: true,
    carcasses: [
      {
        weight: 150,
        code: 634,
        standard: "Boa",
        bought_at: new Date(),
        type: "Dianteiro",
        gender: "Macho",
        provider: { name: "Produtor A" },
      },
      {
        weight: 150,
        code: 634,
        standard: "Boa",
        bought_at: new Date(),
        type: "Dianteiro",
        gender: "Macho",
        provider: { name: "Produtor A" },
      },
      {
        weight: 150,
        code: 634,
        standard: "Boa",
        bought_at: new Date(),
        type: "Dianteiro",
        gender: "Macho",
        provider: { name: "Produtor A" },
      },
      {
        weight: 150,
        code: 634,
        standard: "Boa",
        bought_at: new Date(),
        type: "Dianteiro",
        gender: "Macho",
        provider: { name: "Produtor A" },
      },
    ],
    showEmployeeFilesModal: false,
    showFireEmployeeModal: false,
    employeeType: "hired",
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "short",
      timeStyle: "short",
    }),
    carcass: null,
    showCarcassModal: false,
  }),
  mounted() {
    this.fetchCarcasses();
  },
  computed: {},
  methods: {
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return this.dateFormatter.format(new Date(c));
      else return "desconhecido";
    },
    deleteCarcass(carcass) {
      if (carcass)
        fetch(`${this.$store.state.apiUrl}carcasses/${carcass.uid}`, {
          credentials: "include",
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else throw response.text();
          })
          .then(() => {
            this.fetchCarcasses();
            this.$notify({
              title: "Carcaça removido com sucesso.",
              type: "success",
              position: "bottom-right",
            });
          })
          .catch((e) => {
            this.$notify({
              title: "Não foi possível remover a carcaça",
              type: "error",
              message: e.message,
              position: "bottom-right",
            });
          });
    },
    openCarcassModal(e) {
      (this.carcass = e), (this.showCarcassModal = true);
    },
    discountWeight(c) {
      c.weight = Number(c.total_weight || 0) - Number(c.tara || 0);
    },
    updateCarcass(carcass) {
      if (carcass) {
        this.discountWeight(carcass);
        fetch(`${this.$store.state.apiUrl}carcasses/${carcass.uid}`, {
          credentials: "include",
          method: "PUT",
          body: JSON.stringify({ ...carcass }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            if (response.status == 200) return await response.json();
            else throw await response.json();
          })
          .then(
            (e) => {
              ElNotification.success({
                title: e.message,
                position: "bottom-right",
              });
            },
            (e) => {
              ElNotification.error({
                title: "Não foi possível alterar a função",
                message: e.message,
                position: "bottom-right",
              });
            }
          );
      }
    },
    fetchCarcasses() {
      this.isLoading = true;
      fetch(`${this.$store.state.apiUrl}carcasses`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.carcasses = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
tr.unavailable {
  background-color: #fffde7;
}
tr.problem {
  background-color: #fbe9e7;
}
</style>