<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow" width="90%">
    <form>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Data de cadastro:</h4>
        <el-date-picker
          :modelValue="carcass_.bought_at"
          disabled
          type="datetime"
          size="medium"
          format="DD/MM/YYYY HH:mm:ss"
        >
        </el-date-picker>
      </el-row>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Sexo:</h4>
        <el-switch
          v-model="carcass_.gender"
          active-text="Macho"
          active-value="male"
          inactive-text="Fêmea"
          inactive-value="female"
          inactive-color="#409EFF"
        >
        </el-switch>
      </el-row>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Tipo:</h4>
        <el-switch
          v-model="carcass_.type"
          active-text="Dianteiro"
          active-value="front"
          inactive-text="Traseiro"
          inactive-color="#409EFF"
          inactive-value="back"
        >
        </el-switch>
      </el-row>
      <base-input
        mask="#*"
        v-model="carcass_.code"
        label="Número:"
      ></base-input>
      <el-row>
        <el-col :md="9" :sm="24">
          <base-input
            type="money"
            v-model="carcass_.total_weight"
            :minimumFractionDigits="3"
            label="Peso:"
          ></base-input>
        </el-col>
        <el-col :md="9" :sm="24">
          <base-input
            type="money"
            v-model="carcass_.tara"
            :minimumFractionDigits="3"
            label="Tara:"
          ></base-input>
        </el-col>
        <el-col :md="6" :sm="24">
          <base-input
            type="money"
            disabled
            v-model="carcass_.weight"
            :minimumFractionDigits="3"
            label="Liquido:"
          ></base-input>
        </el-col>
      </el-row>
      <el-select v-model="carcass_.standard" size="medium">
        <template #prefix>Qualidade:</template>
        <el-option
          v-for="item in Standards"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>

      <el-select
        v-model="carcass_.provider_id"
        filterable
        remote
        clearable
        size="medium"
        reserve-keyword
        placeholder="Digite o nome do produtor para buscar"
        :remote-method="fetchProviders"
        :loading="isLoadingSearch"
      >
        <el-option
          v-for="item in Providers"
          :key="item?.uid"
          :label="item?.name"
          :value="item?.uid"
        >
        </el-option>
      </el-select>
      <base-input v-model="carcass_.obs" label="Observação:"></base-input>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :loading="isLoadingSave"
          class="button"
          @click="save"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";

export default {
  props: ["carcass", "showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      carcass_: { ...this.carcass },
      isLoadingSave: false,
      standards: null,
      isLoadingSearch: true,
      providers: null,
      systemActions: [],
      intervalPooling: null,
    };
  },
  watch: {
    carcass(v) {
      this.carcass_ = { ...v };
      this.carcass_.provider_id = v?.provider?.uid;
      if (v?.provider) this.providers = [v?.provider];
    },
    "carcass_.provider_id": function (v) {
      if (v === "remove") this.carcass_.provider_id = null;
    },
    "carcass_.total_weight": function () {
      this.discountWeight();
    },
    "carcass_.tara": function () {
      this.discountWeight();
    },
  },
  mounted() {
    this.intervalPooling = setInterval(this.updateBoughtAt, 1000);
    this.fetchStandards();
  },
  beforeUnmount() {
    clearInterval(this.intervalPooling);
  },
  computed: {
    Standards() {
      return this.standards?.map((s) => s.value) || [];
    },
    ModalTitle() {
      return !this.isNew ? "Editar carcaça" : "Cadastrar carcaça";
    },
    Providers() {
      return [
        this?.providers?.length
          ? { uid: "remove", name: "Limpar seleção" }
          : {},
        ...(this.providers || []),
      ];
    },
    isNew() {
      return !this.carcass;
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    updateBoughtAt() {
      if (this.isNew) this.carcass_.bought_at = new Date();
    },
    save() {
      this.isLoadingSave = true;
      return this.carcass ? this.updateCascass() : this.createCarcass();
    },
    discountWeight() {
      this.carcass_.weight = Math.max(
        0,
        Number(this.carcass_.total_weight || 0) -
          Number(this.carcass_.tara || 0)
      );
    },
    createCarcass() {
      //this.discountWeight();
      fetch(`${this.$store.state.apiUrl}carcasses`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(this.carcass_),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            this.carcass_["code"] = null;
            this.carcass_["total_weight"] = null;
            this.carcass_["standard"] = null;
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao cadastrar a carcaça",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => (this.isLoadingSave = false));
    },
    updateCascass() {
      //this.discountWeight();
      fetch(`${this.$store.state.apiUrl}carcasses/${this.carcass_.uid}`, {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify({ ...this.carcass_ }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao atualizar a carcaça",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
          this.shouldShow = false;
        });
    },
    fetchStandards() {
      const url = new URL(`${this.$store.state.apiUrl}system/preferences`);
      url.search = new URLSearchParams({
        key: "defined_standards",
      });
      fetch(url, {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          this.isLoadingSearch = false;
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then((e) => (this.standards = e));
    },
    fetchProviders(queryName) {
      const url = new URL(`${this.$store.state.apiUrl}partners`);
      url.search = new URLSearchParams({
        is_provider: true,
        searchName: queryName,
      });
      fetch(url, {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          this.isLoadingSearch = false;
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then((e) => (this.providers = e));
    },
  },
  name: "CarcassModal",
};
</script>
<style>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
span.el-switch__label.is-active {
  border: 1px solid #409eff;
  border-radius: 20px;
  padding: 2px;
  color: white;
  background-color: #409eff;
}
</style>